<template>
  <div class="currency-wrapper">
    <a-popover
      popupContainer="body"
      :popup-visible="Boolean(tipContent)"
      position="bl"
      class="unit-popover"
      content-class="unit-popover-content"
      arrow-class="unit-popover-arrow"
      :popup-translate="[-5, -10]"
    >
      <a-input
        :class="['currency-input', showCusValid ? 'required' : '']"
        size="small"
        :modelValue="currentValue"
        :placeholder="placeholder"
        :ref="(el) => (inputRef = el)"
        :disabled="disabled"
        :maxLength="15"
        @focus="handleFocus"
        @blur="handleBlur"
        @update:modelValue="handleUpdateValue"
        @input="handleInput"
        @change="valueChange"
        v-bind="elementProps"
      >
        <template #suffix v-if="$slots.suffix">
          <slot name="suffix" />
        </template>
      </a-input>
      <template #content>
        <!-- <div :class="['curr-tip', isNegative ? 'negative' : '', tipContent.length > 2 ? 'two' : null]" v-if="tipContent"> -->
        <!-- <i class="arrow"></i> -->
        <span class="tip">{{ tipContent }}</span>
        <!-- </div> -->
      </template>
    </a-popover>
  </div>
</template>

<script lang="ts" setup name="LocaleStringInput">
import { formatNumber, handlePasteValue } from '@/views/beforeInv/edit/transaction/utils'
import { computed, nextTick, onMounted, onUnmounted,PropType, ref, toRef, toRefs, watch } from 'vue'
import { getDecimalPlacesFromScientificNotation } from '@/utils/currency'
const props = defineProps({
  elementProps: Object as PropType<object>,
  modelValue: [String, Number] as PropType<string | number>,
  displayFormattedValue: Boolean as PropType<boolean>,
  outerCtrl: Boolean as PropType<boolean>,
  placeholder: String as PropType<string>,
  disabled: Boolean as PropType<boolean>,
  isAllowNegative: {
    type: Boolean as PropType<boolean>,
    default: true,
  },
  needParseFloat: { // 关键指标不抹零
    type: Boolean,
    default: false // 所有金额，数字 不抹零
  },
  showCusValid: {
    type: Boolean,
    default: false
  }
})
const emit = defineEmits(['update:modelValue', 'focus', 'blur', 'handleBlurClose', 'valueChange'])

const isFocusing = ref(false)
const outerValue = toRef(props, 'modelValue')
const inputRef = ref()
const tipContent = ref('')
const isNegative = ref(false)

const formattedValue = computed(() => {
  let tmp = props.modelValue ?? null
  if (tmp === '' || tmp === null) {
    return ''
  }
  if (typeof tmp != 'string') {
    // tmp = String(tmp).replace(/,/g, '')
    tmp = (tmp + '').replace(/,/g, '') // String()会抹零所有不用
  }
  let num = props.needParseFloat ? formatNumber(Number(tmp), { maximumFractionDigits: 15 }) : cusFormatNumber(tmp)
  return num
})

const cusFormatNumber = (value: number | string) => {
  // const newNum = String(value)
  const newNum = value + '' // String()会抹零所有不用
  const values = newNum.split('.');
  values[0] = values[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  value = values.join('.')
  return value
}

const displayFormatted = computed(() => {
  if (props.outerCtrl) {
    return props.displayFormattedValue
  } else {
    return !isFocusing.value
  }
})
const currentValue = computed(() => {
  if (isTempValue.value) {
    return tempValue.value
  } else if (displayFormatted.value) {
    return formattedValue.value ?? ''
  } else {
    return outerValue.value ?? ''
  }
})
const type = computed(() => {
  return displayFormatted.value ? 'string' : 'number'
})
// 处理剪贴板粘贴数字文本
const setListenPaste = () => { 
  nextTick(() => {
    const dom  = inputRef.value?.$el
    dom.addEventListener('paste', handlePaste)

  })
}
const handlePaste = (event:any) => {
  const lastClp = handlePasteValue(event);
  emit('update:modelValue', lastClp)
}
const removeListenPaste = () => {
  const dom  = inputRef.value?.$el
  dom && dom.removeEventListener('paste', handlePaste)
}

onMounted(() => {
  setListenPaste()
})
onUnmounted(() => {
  removeListenPaste()
})
const handleInput = (e) => {
  // e = e + ''
  if (e.length < 1) {
    tipContent.value = ''
    return
  }

  if (e.includes('-')) {
    isNegative.value = true
  } else {
    isNegative.value = false
  }
  const int = Number(e.split('.')[0]) ?? ''
  let len = isNaN(int) ? '' : int.toString().length
  // let len = e != 'undefined' ? e.split('.')[0].length : 0

  if (len < 3 || len > 13) {
    tipContent.value = ''
    return
  }

  let tmpVal = [
    '个',
    '十',
    '百',
    '千',
    '万',
    '十万',
    '百万',
    '千万',
    '亿',
    '十亿',
    '百亿',
    '千亿',
    '万亿',
  ][len - (isNegative.value ? 2 : 1)]
  tipContent.value = tmpVal
}

const setStep = (step: string | number) => {
  nextTick(() => {
    const dom = inputRef.value?.$el?.querySelector('input')
    if (dom) dom.step = step
  })
}

const handleFocus = () => {
  const isString = typeof currentValue.value === 'string'
  isString && handleInput((currentValue.value as string).replace(/,/g, ''))
  isFocusing.value = true

  setStep('any')
  emit('focus')
}

const handleBlur = () => {
  isFocusing.value = false
  tipContent.value = ''
  // const tmp = props.modelValue?.toString().trim()
  const tmp = (props.modelValue + '').trim()
  const value = isNaN(Number(tmp)) ? '' : tmp
  setStep('any')
  
  isTempValue.value = false

  // 当时为什么加 update 忘记了，现在 table 加这行就导致单元格编辑闪一下消失
  // emit('update:modelValue', isMinus ? '' : value)

  const tempValue = value.replace(/^0*(\d+)/,'$1'); // 处理0开头的情况
  
  emit('blur', tempValue)
  emit('handleBlurClose')
}
const valueChange = (value: string | number) => {
  if(typeof value === 'number') {
    value = value + ''
  }
  let v = value
  const Decimal = getDecimalPlacesFromScientificNotation(value)
  if(Decimal) {
    v = Number(v).toFixed(Decimal)
  }
  let tempValue =  v?.replace(/^0*(\d+)/,'$1'); // 处理0开头的情况

  // 处理知只输入负号“-”的情况
  if (tempValue?.length === 1 && tempValue === '-') {
    tempValue = ''
  }
  emit('update:modelValue', tempValue)
  emit('valueChange', tempValue)
}
const isMinus = ref(false)
const endsWithDot = ref(false)
const tempValue = ref('')
const isTempValue = ref(false)
const handleUpdateValue = (v: string | number) => {
  // v = v !== '' ? v.toString().trim().replaceAll('。', '.') : ''
  v = v !== '' ? (v + '').trim().replaceAll('。', '.') : ''
  const beforeValue = props.modelValue
  const isEndWithDot = v.endsWith('.')
  const nonStartWithDot = v.startsWith('.')
  let isErrorValue = false
  // 不允许输入负数
  if (!props.isAllowNegative && v.indexOf('-') > -1) {
    v = v.replace('-', '')
  }
  let validValue = v

  if (v.split('.').length > 2 || (isEndWithDot && nonStartWithDot)) {
    // isErrorValue = true
    isTempValue.value = true
    tempValue.value = v.slice(0, -1)
    return
  } else if (['-', '-0', '-0.', '0.'].includes(v)) {
    isTempValue.value = true
    tempValue.value = v
    validValue = '0'
  } else if (!['', null, undefined].includes(v) && isNaN(Number(v))) {
    validValue = (beforeValue ?? '').toString()
    // validValue = (beforeValue ?? '') + ''
    isTempValue.value = false
    // emit('update:modelValue', beforeValue)
    inputRef.value.$el.querySelector('input').value = beforeValue
  } else if (
    !['', null, undefined].includes(v) &&
    !isNaN(Number(v)) &&
    Number(v).toString() !== v
  ) {
    emit('update:modelValue', props.needParseFloat ? Number(v) : v)
    tempValue.value = v
    isTempValue.value = true
    return
  } else {
    isTempValue.value = false
  }
  const finallyValue = validValue === '' ? '' : props.needParseFloat ? Number(validValue) : validValue
  emit('update:modelValue', finallyValue)
}

const blur = () => {
  inputRef.value?.focus()
  nextTick(() => {
    inputRef.value?.blur()
  })
}
defineExpose({
  blur,
  focus: () => {
    inputRef.value?.focus()
  },
})

onMounted(() => {
  setStep('any')
})
</script>

<style lang="scss" scoped>
.currency-wrapper {
  position: relative;
  width: 100%;
  .curr-tip {
    position: absolute;
    top: 26px;
    width: fit-content;
    margin-left: 4px;
    z-index: 99999;
    &.two {
      margin-left: 0;
    }
    &.negative {
      margin-left: 10px;
      &.two {
        margin-left: 0;
      }
    }
    .arrow {
      position: absolute;
      z-index: 1;
      border: 5px solid transparent;
      border-bottom-color: #848a93;
      background: transparent;
      top: -9px;
      left: calc(50%);
      transform: translate(-50%);
    }
    .tip {
      height: 18px;
      line-height: 18px;
      padding: 0 4px;
      font-size: 12px;
      color: white;
      background: #848a93;
      border-radius: 5px;
      z-index: 10;
    }
  }
  .currency-input {
    padding: 0 8px;
    &:deep(.arco-input-number-step) {
      display: none;
    }
    &.required {
      border-color: red;
    }
  }
  :deep(.arco-input-disabled .arco-input:disabled) {
    -webkit-text-fill-color: #86909c;
  }
}
</style>

<style lang="scss">
.unit-popover {
  z-index: 9999999 !important;
  .unit-popover-content {
    min-width: 32px;
   
    padding: 0 4px;
    font-size: 12px;
    color: white;
    background: #848a93;
    height: 18px;
    line-height: 16px;
    border-color: #848a93;
    .arco-popover-content {
      margin-top: 0;
      text-align: center;
    }
  }
  .unit-popover-arrow {
    width: 8.86px;
    height: 8.86px;
    opacity: 1;
    z-index: -1;
    border-color: #848a93;
    background: #848a93;
    left: 7px!important;
    top: 0px;
    transform: translate(50%, -50%) rotate(45deg) !important;
  }
  
}
</style>
