<template>
  <a-dropdown
    class="custom-user-tag-select"
    v-model:popup-visible="popupVisible"
    trigger="click"
    :popupContainer="popupContainer"
    :style="{ '--w': `${triggerWidth}px` }"
    @popup-visible-change="handlerVisibleChanage"
  >
    <div class="custom-tag-list-outer" ref="selectDom">
      <tagList
        v-if="finished"
        ref="tagList"
        :from="from"
        :tags="optionTagsValue"
        :state="1"
        @delVal="handleDel"
      />
    </div>
    <template #content v-if="finished">
      <div :class="['dropdown', from]">
        <a-input
          ref="searchInputRef"
          class="search-input"
          v-model="keyword"
          :placeholder="$t('plat_c.app_c.form.chazhaoxuanxiang')"
        />
        <div class="option-list" :style="'max-height:' + optionHeight">
          <template v-if="filteredOptions.length > 0">
            <div
            v-for="(option, index) in filteredOptions"
            :key="option[idKey]"
            :class="{
            'option-item':true,
            'error-option':from=='excelTable'&& option?.error,
            'error-option-hidden':from=='excelTable'&& option?.hidden
          }"
              @click="clickOption(option)"
            >
              <img class="avatar-img" :src="option.avatar" />
              <div class="label-area">
                <span class="label-name">{{ option[labelKey] }}</span>
                <!-- <span class="label-dep">{{ option.deptNames || $t('plat_c.app_c.form.bumen') }}</span> -->
              </div>
              <i v-if="checkItem(option)" class="iconfont icon-checked icon-close-med" />
            </div>
          </template>
          <template v-else>
            <div class="no-data">{{$t('plat_c.app_c.form.meiyouxuanxiang')}}</div>
          </template>
        </div>
      </div>
    </template>
  </a-dropdown>
</template>
<script>
const W_FULL = 12 //汉字宽度，跟font-size大小一样
const W_HALF = 10 //字母数字符号宽度, 字符从7~10不等，字母约为8.7，数字约为7.2 不再精确计算

import tagList from './tagList.vue'
import _ from 'lodash'
import { onClickOutside } from '@vueuse/core'

export default {
  name: 'TagUserSelect',
  components: {
    tagList,
  },
  props: {
    modelValue: [String, Number, Array],
    from: {
      type: String,
      default: 'normal', //组件使用来源，filter筛选，normal其他
      // default: ()=>{ return 'normal' }
    },
    type: {
      type: String,
      default: 'single',
    }, //single 单选， multiple 多选
    // multiple: {
    //   type: Boolean,
    // },
    otherType:{
      type: String,
      default: 'single', //兼容项目创建人的时候传进去多个人员 但是要支持单选
    },
    defaultVisible: {
      type: Boolean,
    },
    options: Array,
    popupContainer: String,
    optionHeight: {
      type: String,
      default: () => {
        return '100px'
      },
    },
    optionKeys: {
      type: Object,
      default() {
        return {
          label: 'label',
          id: 'id',
          color: 'color',
        }
      },
    },
    
  },
  data() {
    return {
      rawOptions: [],
      localValues: null,
      localOptions: [],
      state: false,
      keyword: '',
      triggerWidth: 0,
      finished: false,
      popupVisible: false,
    }
  },
  computed: {
    multiple() {
      return this.type === 'multiple'
    },
    labelKey() {
      return this.optionKeys.label
    },
    idKey() {
      return this.optionKeys.id
    },
    colorKey() {
      return this.optionKeys.color
    },
    filteredOptions() {
      const lowerCaseKeyword = this.keyword?.toLowerCase() ?? ''

      return this.options.filter((opt) => {
        const lowerCaseLabel = opt[this.labelKey]?.toLowerCase() ?? ''

        return lowerCaseLabel.includes(lowerCaseKeyword)
      })
    },
    optionTagsValue() {
      const values = this.multiple
        ? this.localValues || []
        : this.localValues
        ? [this.localValues]
        : []

      return values
        .map((value) => {
          const option = this.options.find((opt) => {
            return opt[this.idKey] === value
          })

          return option
            ? {
                ...option,
                color: option[this.colorKey],
              }
            : null
        })
        .filter((i) => i !== null)
    },
  },
  watch: {
    popupVisible: {
      handler: function (n) {
        if (n) {
          this.$nextTick(() => setTimeout(() => this.$refs.searchInputRef?.focus(), 100))
        }
      },
      immediate: true,
    },
    modelValue: {
      immediate: true,
      deep: true,
      handler(v) {
        this.localValues = _.cloneDeep(v)
      },
    },
    options: {
      immediate: true,
      deep: true,
      handler(v) {
        this.localOptions = this.formatOptions(v)

        // let tmpOptions = this.formatOptions(v)
        // tmpOptions.forEach((ii) => {
        //   ii.label = ii.name
        // })
        // this.localOptions = tmpOptions
        // this.rawOptions = _.cloneDeep(tmpOptions)
      },
    },
    searchKey: {
      immediate: true,
      handler(v) {
        this.filterOptions()
      },
    },
  },
  methods: {
    checkItem(opt) {
      // if (this.from != 'filter') return false

      const values = this.multiple
        ? this.localValues || []
        : this.localValues
        ? [this.localValues]
        : []

      return values.includes(opt[this.idKey])

      // return !!this.localValues.find((ii) => {
      //   return ii.id == item.id
      // })
    },
    focus() {
      // this.state = true
      this.popupVisible = true
    },
    filterOptions() {
      let word = this.searchKey
      let tmpList = this.rawOptions.filter((ii) => {
        return ii[this.labelKey].includes(word)
      })
      this.localOptions = this.formatOptions(tmpList)
    },
    //格式化选项
    formatOptions(list) {
      let tmpList = _.cloneDeep(list)
      tmpList.forEach((ii) => {
        ii.showLabel = ii[this.labelKey]
      })
      return tmpList
    },
    handlerVisibleChanage(flag){
      if(!flag){
        this.$emit('update:model-value', this.localValues)
      }
    },
    clickOption(option) {
      const value = option[this.idKey]
      if(this.from=='excelTable' && this.otherType=='single'){
        this.localValues = this.localValues === value ? null : [value]
        this.popupVisible = false
      }else{
        if (!this.multiple) {
          this.localValues = this.localValues === value ? null : value
        } else {
          this.localValues = this.localValues?.includes(value)
            ? this.localValues.filter((v) => v !== value)
            : [...(this.localValues ? this.localValues : []), value]
        }
      }
      this.keyword = ''
      this.$emit('update:model-value', this.localValues)
    },
    handleDel(index) {
      if (this.type == 'single') {
        this.localValues = null
      } else {
        this.localValues.splice(index, 1)
      }
      this.$emit('update:model-value', this.localValues)
    },
  },
  mounted() {
    // 外部挂载特殊处理，不然宽度取不到，会导致计算错误
    this.$nextTick(() => {
      setTimeout(() => {
        const { selectDom } = this.$refs
        let boundingClientRect=selectDom?.getBoundingClientRect()
        if(!boundingClientRect)return
        const { width } = boundingClientRect

        this.triggerWidth = width
        this.finished = true
      }, 0)
    })

    // const { domSelect } = this.$refs
    // onClickOutside(domSelect, () => {
    //   this.state = 0
    // })
  },
}
</script>

<style lang="scss">
.custom-user-tag-select {
  width: var(--w);
  .arco-dropdown {
    border-radius: 6px;
    padding: 0;
    border: 1px solid #dbdfe1;
  }
}
</style>

<style lang="scss" scoped>
.custom-tag-list-outer {
  padding: 6px 10px;
  min-height: 30px;
  max-height: 60px;
  overflow-y: auto;
  overflow-x: hidden;
  // border: 1px solid pink;
  width: 100%;
  :deep(.icon-arrow) {
    line-height: 20px;
    margin-top: 0;
  }
}
.dropdown {
  width: 100%;
  // border: 1px solid #dbdfe1;
  border-radius: 6px;
  .search-input {
    border: none;
    height: 38px;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    border-bottom: 1px solid #dbdfe1;
    padding: 0 8px;
  }
  .option-list {
    margin-right: 3px;
    padding: 4px 0 4px 4px;
    width: 100%;
    overflow-y: auto;
    .option-item {
      display: flex;
      align-items: center;
      margin: 0 4px 0 0;
      padding: 0px 8px 0 13px;
      height: 48px;
      cursor: pointer;
      &.error-option{
        display:none
      }
      &:hover {
        background: #eceeee;
        border-radius: 4px;
      }
      .icon-checked {
        margin-left: 4px;
        font-size: 18px;
        color: #236bf8;
      }
      .avatar-img {
        width: 32px;
        height: 32px;
        border-radius: 16px;
        margin-right: 8px;
      }
      .label-area {
        display: flex;
        flex-direction: column;
        flex: 1;
        // height: 38px;
        overflow: hidden;
        .label-name {
          display: block;
          height: 20px;
          width: fit-content;
          max-width: 100%;
          line-height: 20px;
          font-size: 14px;
          font-weight: 400;
          color: #1f2329;
          user-select: none;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        .label-dep {
          height: 18px;
          line-height: 18px;
          font-size: 12px;
          color: #86909c;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }
    .no-data {
      text-align: center;
      // margin: 4px 0;
      font-size: 12px;
      font-family: PingFangSC;
      color: #86909c;
      font-weight: 400;
      font-size: 14px;
      height: 32px;
      line-height: 32px;
    }
  }
  &.filter {
    .option-list {
      .option-item {
        display: flex;
        align-items: center;
        justify-content: space-between;
        .icon-checked {
          margin-left: 4px;
          font-size: 18px;
          color: #236bf8;
        }
      }
    }
  }
}
</style>
