<template>
  <div ref="tagListModule" :class="['tag-user-list-module', from]">
    <!-- 筛选设置中只有一种状态 -->
    <template v-if="from=='filter'">
      <div :class="{
        'filter-list': true,
        'placeholder': !filterList?.length
      }">
        <span v-if="!filterList?.length" class="placeholder-text">
          {{placeholder || $t('plat_c.app_c.form.qingxuanze')}}
        </span>
        <span 
          v-for="(item, index) in filterList" 
          :class="['tag-item', {more: item.cType == 'more'}]" 
          :key="item.id"
          :style="'background:'+item.color + (item.cType=='more'?(';width:'+ item.cWidth):'')">
            <template v-if="item.cType!='more'  ">
              <img v-if="item.avatar && meta.showAvatar" class="avatar-img" :src="item.avatar" />
              <span v-else class="avatar-no-img">{{item.label[0]}}</span>
            </template>
            <a-tooltip v-if="item.displayedLabel!=item.label" :content="item.label">
              <span class="label">{{item.displayedLabel}}</span>
            </a-tooltip>
            <span v-else class="label">{{item.displayedLabel}}</span>
            <i v-if="item.cType!='more'" class="icon-del iconfont icon-icon_close_med" @click="handleDel(index, $event)"/>
        </span>
      </div>
      <i v-if="isWeekReport" class="iconfont icon-icon_mb2"/>
      <i v-else class="iconfont icon-icon_d_arrow filter-arrow-icon"/>
      <!-- <i class="icon-tmp icon-2x10 filter-arrow-icon" /> -->
    </template>
    <template v-else-if="state == 0">
      <div class="show-list">
        <template v-if="computedShowList.length>0">
          <span 
          v-for="(item, index) in computedShowList" 
          :class="['tag-item', {more: item.cType == 'more'}]" 
          :key="item.id"
          :style="'background:'+item.color + (item.cType=='more'?(';min-width:'+ item.cWidth):'')">
            <template v-if="item.cType!='more' ">
              <img v-if="item.avatar && meta.showAvatar" class="avatar-img" :src="item.avatar" />
              <span v-else class="avatar-no-img">{{item.label[0]}}</span>
            </template>
            <a-tooltip v-if="item.displayedLabel!=item.label" :content="item.label">
              <span class="label">{{item.displayedLabel}}</span>
            </a-tooltip>
            <span v-else class="label">{{item.displayedLabel}}</span>
        </span>
        </template>
        
      </div>
    </template>
    <template v-else>
      <div class="edit-list">
        <div class="item-list">
          <div class="tag-item" 
            v-for="(item, index) in editList" 
            :key="item.id"
            :style="'background:'+item.color"
            :class="[{ 'error-tag':item?.error}]"
            >
            <template v-if="item.cType!='more' ">
              <img v-if="item.avatar && meta.showAvatar" class="avatar-img" :src="item.avatar" />
              <span v-else class="avatar-no-img">{{item.label[0]}}</span>
            </template>
            <a-tooltip v-if="item.displayedLabel!=item.label" :content="item.label">
              <span class="label">{{item.displayedLabel}}</span>
            </a-tooltip>
            <span v-else class="label">{{item.displayedLabel}}</span>
            <i class="icon-del iconfont icon-icon_close_med" @click="handleDel(index, $event)"/>
          </div>
        </div>
        <i class="icon-arrow iconfont icon-icon_d_arrow"/>
      </div>
    </template>
  </div>
</template>
<script>
import _ from 'lodash';

const W_AVATAR = 20;  //头像宽高
const W_AVATAR_MAR = 6; //头像右侧间距
const W_FULL = 12; //汉字宽度，跟font-size大小一样
const W_HALF = 10; //字母数字符号宽度, 字符从7~10不等，字母约为8.7，数字约为7.2 不再精确计算
const W_PLUS = 8; //+号宽度7.2
const W_PAD = 8;  //颜色块左右padding宽度，通用
const W_PAD_L = 2;  //颜色块左右padding宽度，头像左侧
const W_PAD_R = 8;  //颜色块左右padding宽度, 数字左右宽度都是8
const W_MAR = 4;  //tag之间宽度
const W_DOT = 10;  //3个点省略号宽度
const W_XX = 10;  //x号宽度
const W_ARROW = 10; //下拉箭头宽度
const NUM_COLOR = '#DBE0E3';

export default {
  name: 'UserTagList',
  props:{
    from: {
      type: String,
      default: 'normal',
    },   //组件使用来源，filter筛选，normal
    tags: Array,
    state: Number, //0为展示态，1为编辑态
    containerWidth: {
      type: Number,
      default: null,
    },
    workerComputedData: {
      type: Object,
      default: () => ({}),
    },
    labelKey: {
      type: String,
      default: 'label',
    },
    placeholder: {
      type: String,
    },
    // 一些配置 showAvatar 是否展示头像 showDelete 
    meta: {
      type: Object,
      default: () => ({
        showAvatar: true
      }),
    },
    isWeekReport: {
      type: Boolean,
      default: false,
    }
  },
  data(){
    return{
      fullWidth: 0,
      rawTags: [],  //存储原始数组
      filterList: [], //筛选类型的选项列表
      showList: [], //展示态的选项列表
      editList: [], //编辑态选项列表
    }
  },
  watch:{
    tags:{
      immediate: true,
      deep: true,
      handler(v) {
        if (this.workerComputedData?.showTagList?.length) {
          // this.showList = this.workerComputedData.showTagList || []
          // this.editList = this.workerComputedData.editTagList || []
        } else {
          this.rawTags = _.cloneDeep(v);
          this.formatTags(); 
        }
      }
    },
  },
  computed: {
    computedShowList() {
      if (this.workerComputedData?.showTagList?.length) {
        return this.workerComputedData.showTagList
      } else {
        return this.showList || []
      }
    }
  },
  methods:{
    handleDel(index, evt){
      evt.stopPropagation()
      evt.preventDefault()
      
      this.$emit('delVal', index);
    },
    //添加宽度数组
    addWidth(tag){
      let str = tag.label;
      //添加3个辅助key
      tag.charArr = Array.from(str);
      tag.widthArr = Array.from(str).map((cur)=>{
        if(/[\u4e00-\u9fa5]/.test(cur)){
          return W_FULL;
        }else{
          return W_HALF;
        }
      });
      let allLen = tag.widthArr.reduce((pre,cur)=>{ return pre + cur },0)
      tag.showWidth = W_PAD_L + W_AVATAR + W_AVATAR_MAR + allLen + W_PAD_R; 
      tag.editWidth = W_PAD_L + W_AVATAR + W_AVATAR_MAR + allLen + 6 + W_XX + W_PAD_R; //8为tag颜色padding, 4为tag之间的间距
    },
    //获取+num这个tag的宽度, 样式上同样做个矫正
    getNumWidth(len){
      //暂时不考虑大于100个选项
      let width;
      if(len < 10){
        width = W_PAD_R + W_PLUS * 2 + W_PAD_R;
      }else{
        width = W_PAD_R + W_PLUS * 3 + W_PAD_R;
      }
      // console.log('【NumWidth】', width);
      return width;
    },
    //添加点点点, fWidth只算文字+点点点长度，其他padding, icon宽度等都不算
    fitLabel(tag, fWidth){
      // console.log('【FitLabelWidth】', fWidth - W_DOT);
      let str = '';
      let curW = 0;
      tag.widthArr.find((ii, index)=>{
        if(curW + ii <= fWidth - W_DOT){  //如果宽度小于总宽度-3个点省略号宽度
          str += tag.charArr[index];
          curW += ii;
          return false;
        }else{
          return true;
        }
      }) 
      tag.displayedLabel = str+'...';  //重新拼凑 
    },
    //获得数字标签的label, 从index开始算label
    formatMoreLabel(list, index){
      let str = [];
      for(let len = list.length; index < len;index++){
        str.push(list[index].label)
      }
      return str.join('、');
    },
    formatTags(){
      if(this.fullWidth == 0) return;
      if(this.rawTags.length < 1){
        this.filterList = [];
        this.editList = [];
        this.showList = [];
        return;
      }
      let fullWidth = this.fullWidth;
      let editFullWidth = fullWidth - W_ARROW - W_MAR;
      // console.log('【editFullWidth】', editFullWidth);
      this.rawTags.forEach((ii)=>{
        this.addWidth(ii);  //先计算每个tag整体宽度
      })
      
      //如果是筛选弹窗里的选项,筛选里始终有箭头
      if(this.from == 'filter'){
        // console.log(this.rawTags)
        let filterList = _.cloneDeep(this.rawTags)
        filterList.forEach((ii)=>{
          ii.displayedLabel = ii.label;
        });
        this.filterList = Object.freeze(filterList);
        // return;
        if (!this.isWeekReport) return // 周报的人员筛选需要显示+N

        /***  先不展示数字   ****/
        //如果只有单个选项
        if(this.rawTags.length == 1){
          if(filterList[0].editWidth > editFullWidth){  //如果超出总长度
            let tag = filterList[0];
            this.fitLabel(tag, editFullWidth - W_PAD_L - W_PAD_R - 6 - W_XX); //x号左侧margin 6px
          }else{
            filterList[0].displayedLabel = filterList[0].label;
          }
          this.filterList = Object.freeze(filterList);
        }else{  //大于1个选项的情况
          let allNumWidth = this.getNumWidth(filterList.length - 1);
          /*** 处理展示态情况 *****/
          //如果第一个选项宽度就大于总宽度(总宽度-数字宽度)
          if(filterList[0].editWidth > editFullWidth - allNumWidth - W_MAR){
            let tag = filterList[0];
            this.fitLabel(tag, editFullWidth - allNumWidth - W_MAR - W_PAD_L - W_PAD_R - 6 - W_XX);
            filterList = filterList.slice(0,1);
            filterList.push({
              displayedLabel: `+${this.rawTags.length - 1}`,
              color: NUM_COLOR,
              cWidth: allNumWidth + 'px',
              cType: 'more',  //自定义类型，方便区分
              label: this.formatMoreLabel(this.rawTags, 1)
            })
            this.filterList = Object.freeze(filterList);
          }else{
            let tmpFullWidth = 0;
            let tmpW = 0;
            let tmpList = [];
            filterList.findIndex((ii, index)=>{
              let tmpNumWidth = 0
              if(index < filterList.length - 1){  //如果还不是最后一个
                tmpNumWidth = this.getNumWidth(filterList.length - 1 - index);
                tmpFullWidth = editFullWidth - tmpNumWidth - W_MAR; //数字左侧的margin
              }else{
                tmpFullWidth = editFullWidth;
              }
                    
              if(tmpW + ii.editWidth + W_MAR < tmpFullWidth){ //如果还没超过总宽度（两个标签之间有margin）
                ii.displayedLabel = ii.label;
                tmpList.push(ii);
                tmpW += ii.editWidth;
                return false;
              }else{
                //如果超过了总宽度，不再精确计算，直接隐藏最后一个
                tmpNumWidth = this.getNumWidth(filterList.length - 1 - index + 1);  //此时要算上当前这个index的值，所以+1
                tmpList.push({
                  displayedLabel: `+${filterList.length - index}`,
                  color: NUM_COLOR,
                  cWidth: tmpNumWidth + 'px',
                  cType: 'more',  //自定义类型，方便区分
                  label: this.formatMoreLabel(filterList, index)
                })
                return true;
              }
            })
            this.filterList = Object.freeze(tmpList);
          }
        }
        return;
      }

      let showList = _.cloneDeep(this.rawTags); //展示态列表
      let editList = _.cloneDeep(this.rawTags); //编辑态列表

      //如果只有单个选项
      if(this.rawTags.length == 1){
        //先处理展示态情况
        if(showList[0].showWidth > fullWidth){  //如果超出总长度
          let tag = showList[0];
          this.fitLabel(tag, fullWidth - W_PAD_L - W_PAD_R);
        }else{
          showList[0].displayedLabel = showList[0].label;
        }
        this.showList = Object.freeze(showList);
        
        //处理编辑态情况
        if(editList[0].editWidth > editFullWidth){ //如果大于整个宽度
          let tag = editList[0];
          // this.fitLabel(tag, editFullWidth - W_DOT - W_PAD - W_XX);
          this.fitLabel(tag, editFullWidth - W_PAD_L - W_PAD_R - 6 - W_XX); //x号左侧margin 6px
        }else{
          editList[0].displayedLabel = editList[0].label;
        }
        this.editList = Object.freeze(editList);

      }else{  //大于1个选项的情况
        let allNumWidth = this.getNumWidth(showList.length - 1);
        /*** 处理展示态情况 *****/
        //如果第一个选项宽度就大于总宽度(总宽度-数字宽度)
        if(showList[0].showWidth > fullWidth - allNumWidth - W_MAR){
          let tag = showList[0];
          this.fitLabel(tag, fullWidth - allNumWidth - W_MAR - W_PAD_L - W_PAD_R);
          showList = showList.slice(0,1);
          showList.push({
            displayedLabel: `+${this.rawTags.length - 1}`,
            color: NUM_COLOR,
            cWidth: allNumWidth + 'px',
            cType: 'more',  //自定义类型，方便区分
            label: this.formatMoreLabel(this.rawTags, 1)
          })
          this.showList = Object.freeze(showList);
        }else{
          let tmpFullWidth = 0;
          let tmpW = 0;
          let tmpList = [];
          showList.findIndex((ii, index)=>{
            let tmpNumWidth = 0
            // console.log(index);
            if(index < showList.length - 1){  //如果还不是最后一个
              tmpNumWidth = this.getNumWidth(showList.length - 1 - index);
              tmpFullWidth = fullWidth - tmpNumWidth - W_MAR; //数字左侧的margin
            }else{
              tmpFullWidth = fullWidth;
            }
                   
            if(tmpW + ii.showWidth + W_MAR < tmpFullWidth){ //如果还没超过总宽度（两个标签之间有margin）
              ii.displayedLabel = ii.label;
              tmpList.push(ii);
              tmpW += ii.showWidth;
              return false;
            }else{
              //如果超过了总宽度，不再精确计算，直接隐藏最后一个
              tmpNumWidth = this.getNumWidth(showList.length - 1 - index + 1);  //此时要算上当前这个index的值，所以+1
              tmpList.push({
                displayedLabel: `+${showList.length - index}`,
                color: NUM_COLOR,
                cWidth: tmpNumWidth + 'px',
                cType: 'more',  //自定义类型，方便区分
                label: this.formatMoreLabel(showList, index)
              })
              return true;
            }
          })
          this.showList = Object.freeze(tmpList);
        }
 
        /*** 处理编辑态情况 *****/
        //判断是否有超过总体宽度的单个tag
        editList.forEach((ii)=>{
          if(ii.editWidth + W_MAR > editFullWidth){
            // this.fitLabel(ii, editFullWidth - W_MAR - W_DOT - W_PAD - W_XX);
            this.fitLabel(ii, editFullWidth - W_PAD_L - W_PAD_R - 6 - W_XX); //x号左侧margin 6px
          }else{
            ii.displayedLabel = ii.label;
          }
        })
        this.editList = Object.freeze(editList);
      }
    },
  },
  mounted(){
    const calcWidth = () => {
      let tmpW = this.$refs.tagListModule?.offsetWidth;
      // console.log('【fullWidth】', tmpW);
      this.fullWidth = tmpW;
      this.formatTags();
    }

    if (this.containerWidth) {
      // this.doCalcTags(this.containerWidth)
    } else {
      this.$nextTick(() => {
        setTimeout(calcWidth, 100)
      })
    }

  },
}
</script>
<style lang="scss">
  @import '@/assets/styles/var.scss';
  .tag-user-list-module{
    .filter-arrow-icon {
      line-height: 20px;
      font-size: 12px;
      transform: scale(0.73333);
      &::before {
        color: $icon-color-gray2;
      }
    }
    .tag-item{
      display: flex;
      align-items: center;
      margin-right: 4px;
      padding: 0 8px 0 2px;
      height: 24px;
      line-height: 24px;
      border-radius: 12px;
      white-space: nowrap;
      user-select: none;
      cursor: pointer;
      transition: all .2s ease-in-out;
      &:hover {
        background: #BBCEFC;
      }
      .avatar-img{
        width: 20px;
        height: 20px;
        border-radius: 10px;
        margin-right: 6px;
      }
      .avatar-no-img{
        width: 20px;
        height: 20px;
        border-radius: 10px;
        margin-right: 6px;
        background: #99adff;
        font-size: 12px;
        color: white;
        line-height: 20px;
        text-align: center;
      }
      &.more{
        justify-content: center;
        padding: 0 8px;
      }
      .label{
        font-size: 12px;
        font-weight: 500;
        user-select: none;
      }
    }
    .filter-list{
      display: flex;
      flex: 1;
      align-items: center;
      overflow: hidden;
      .placeholder-text {
        color: #86909C;
      }
      .tag-item{
        .icon-del{
          margin-left: 6px;
          // width: 10px;
          height: 20px;
          font-size: 12px;
          transform: scale(0.833333);
          line-height: 20px;
          cursor: pointer;
          &:hover {
            color: #265CDB;
          }
        }
        .label{
          font-size: 14px;
          color: #1f2329;
          font-weight: 400;
        }
      }
    }
    .show-list{
      display: flex;
    }
    .edit-list{
      display: flex;
      margin-top: -4px;
      .item-list{
        flex: 1;
        display: flex;
        flex-wrap: wrap;
      }
      .tag-item{
        margin: 4px 4px 4px 0;
        width: fit-content;
        &:nth-child(1){
          margin-left: 0;
        }
        .label{
          user-select: none;
        }
        .icon-del{
          margin-left: 6px;
          // width: 10px;
          height: 20px;
          font-size: 12px;
          transform: scale(0.833333);
          line-height: 20px;
          cursor: pointer;
          color: #656a72;
          &:hover {
            color: #2c2f35;
          }
        }
      }
      .icon-arrow{
        margin-left: 4px;
        margin-top: 9px;
        font-size: 12px;
        transform: scale(0.8333);
        transform-origin: center;
        color: #646a73;
        height: 24px;
        line-height: 24px;
        padding-top: 8px;
      }
    }
  }
  .tag-user-list-module.filter{
    display: flex;
    padding-top: 4px;
    .icon-arrow{
      margin-left: 4px;
      margin-top: 2px;
      font-size: 10px;
    }
    .filter-list {
      margin-top: -1px;
    }
  }
</style>