<template>
  <a-popover
    :popup-visible="switchCurrencyPopoverVisible"
    class="currency-popover"
    :show-arrow="false"
    trigger="click"
    :position="position"
    update-at-scroll
    :popup-translate=" from =='excelTable' ? [0, -5] :undefined"
    :render-to-body="renderToBody"
    @update:popup-visible="handleUpdatePopoverVisible"
  >
    <div
      @click="!disabled && switchable && (switchCurrencyPopoverVisible = true)"
      :class="{
        active: !disabled && switchable,
        'switch-trigger': true,
        [contentClassName ?? '']: Boolean(contentClassName),
        focusing: switchCurrencyPopoverVisible,
      }"
    >
      <span class="currency-name"
        >{{ currentCurrency?.englishName
        }}<template v-if="showCNName"> &nbsp;{{ currentCurrency?.chineseName }} </template></span
      >
      <Iconfont v-if="switchable" name="icon_d_arrow" class="arrow-icon" :size="12" />
    </div>
    <template #content>
      <p
        v-for="(item, index) in currencyList"
        :key="index"
        style="cursor: pointer"
        @click.stop.prevent="onSwitchCurrency(item)"
        :class="[
          'currency-item',
          currentCurrency?.englishName === item.englishName ? 'active' : '',
        ]"
      >
        <span class="show-full" style="margin-right: 10px"
          >{{ item.englishName }} {{ item.chineseName }}</span
        >
        <Iconfont v-if="currentCurrency?.englishName === item.englishName" name="close-med" class="close-med"/>
        <!-- <i v-if="currentCurrency?.englishName === item.englishName" class="iconfont icon-close-med"></i> -->
      </p>
    </template>
  </a-popover>
</template>

<script lang="ts" setup name="SwitchCurrencyPopover">
import { computed, nextTick, onMounted, PropType, ref, watchEffect, watch, useSlots } from 'vue'
import { currencyMappingStore, ICurrency } from '@/store/useCurrencyStore'
import Iconfont from '../Iconfont.vue'

const { getCurrencySymbol, currencySymbolList } = currencyMappingStore()

const props = defineProps({
  contentClassName: String as PropType<string>,
  visible: Boolean as PropType<boolean>,
  disabled: Boolean as PropType<boolean>,
  currency: String as PropType<string>,
  renderToBody: Boolean as PropType<boolean>,
  switchable: {
    type: Boolean as PropType<boolean>,
    default: true,
  },
  showCNName: {
    type: Boolean as PropType<boolean>,
    default: true,
  },
  position: {
    type: String as PropType<'br' | 'bl'>,
    default: 'bl',
  },
  customCurrencyLists: {
    type: [Array, String] as PropType<string  | Array<any>>,
    default: null
  },
  from: {
    type: String,
    default: ''
  }
})
const emit = defineEmits(['update-currency', 'update:visible'])

const currency = ref<string>('')
const currentCurrency = computed(() => {
  const symbolLists = props.customCurrencyLists?.length ? props.customCurrencyLists : currencySymbolList || []
  const currencyItem = symbolLists.find((c) => c.englishName === currency.value) 
  if(!currencyItem) {
    const baseCurrency = symbolLists.filter((item) => {
      return item.isBase
    })
    const base = baseCurrency && baseCurrency.englishName || symbolLists[0]?.englishName
    emit('update-currency', base)
    return symbolLists[0]
  }
  return currencyItem
})

const innerVisible = ref(false)
watchEffect(() => {
  if (props.visible && innerVisible.value !== props.visible) {
    innerVisible.value = props.visible
  }
})

const switchCurrencyPopoverVisible = computed<boolean>({
  get() {
    return innerVisible.value
  },
  set(v: boolean) {
    innerVisible.value = v
    emit('update:visible', v)
  },
})

const handleUpdatePopoverVisible = (value: boolean) => {
  if (!value) {
    switchCurrencyPopoverVisible.value = false
  }
}
const onSwitchCurrency = async (c: ICurrency) => {
  currency.value = c.englishName
  handleUpdatePopoverVisible(false)
  emit('update-currency', c.englishName)
  await nextTick()
  switchCurrencyPopoverVisible.value = false // 解决选择币种后没有马上关闭pop框 需要第二次点击才能关闭
}

watchEffect(() => {
  currency.value = props.currency ?? ''
})

const currencyList = computed(() => {
  return props.customCurrencyLists?.length ? props.customCurrencyLists : currencySymbolList || []
})

defineExpose({
  handleUpdatePopoverVisible
})
</script>

<style lang="scss">
.currency-popover {
  .arco-trigger-content {
    padding: 4px;
    width: 144px;
    .arco-popover-content {
      margin: 0;
    }
  }
}
</style>

<style lang="scss" scoped>
.switch-trigger {
  padding-left: 8px;
  position: relative;
  max-width: 100px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  &.active {
    cursor: pointer;
    padding-right: 12px;
    min-width: 44px;
    :deep(.iconfont) {
      display: inline;
    }
  }
  .currency-name {
    display: inline-block;
    font-size: 12px;
  }
  :deep(.iconfont) {
    display: none;
    position: absolute;
    right: -1px;
    top: 50%;
    transform: translateY(-50%) scale(0.83333);
    color: #646A73;
  }
}
.currency-item {
  height: 32px;
  line-height: 32px;
  font-size: 14px;
  padding: 0 8px;
  position: relative;
  transition: color 200ms ease;
  border-radius: 6px;
  .show-full{
    // text-overflow: ellipsis;
    display: -webkit-box; /**对象作为伸缩盒子模型展示**/
    -webkit-box-orient: vertical; /**设置或检索伸缩盒子对象的子元素的排列方式**/
    -webkit-line-clamp: 1; /**显示的行数**/
    overflow: hidden; /**隐藏超出的内容**/
  }

  &.active {
    color: #3272fe;
  }
  &:hover {
    background: #f2f3f5;
  }
  .iconfont {
    position: absolute;
    right: 8px;
    top: 50%;
    transform: translateY(-50%);
  }
}
</style>
