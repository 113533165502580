import axios from 'axios';
import _ from 'lodash';
import { Message } from '@arco-design/web-vue';
import queryString from 'qs';
import * as appTool from '@/utils/appTool.js';

//通用前缀
let apiPrefix = '/api';

function getDefConfig(){
  return {
    timeout: 1000 * 60 * 5, //2分钟
    responseType: '',
    headers:{
      'Content-Type': 'application/json;charset=UTF-8',
      'login-token': appTool.getToken()
    },
  }
}

//合并配置项
function mergeConfig(requestConfig, otherConfig){
  let url;
  if(!otherConfig.noPrefix){
    url = `${apiPrefix}${requestConfig.url}`;
  }
  return _.merge(getDefConfig(),requestConfig, { url });
}

//通用处理返回结果
function handleResult(context, ignore = false){
  const { response } = context;

  if(!(response instanceof Error)){
    let data = response?.data;
    if (!data) return {}
    if(data.code == 1010001){ //跳转登录, 1010001, "登陆信息失效" 
      // appTool.removeToken(); // 刷新token后新换回来的token会被这里删除，所有注释掉，防止刷新token后重新登录失效
      // localStorage.setItem(appTool.getAppId() + '_redirect_url', location.href);
      // location.reload();
      let time = setTimeout(() => {
        localStorage.setItem(appTool.getAppId() + '_redirect_url', location.href);
        location.reload();
        clearTimeout(time);
        time = null;
      }, 30);

    }else if([1010002].includes(data.code)){ // 1010002, "令牌信息错误"
      // Message.error(data.msg);
      data.msg != '登陆信息失效' && Message.error(data.msg); // 登陆信息失效不需要提示错误，已执行自动换token逻辑
      // appTool.removeToken();

    }else if([1010008].includes(data.code)){ //1010008 没有权限
      // appTool.removeToken();
      const redirectUrl = encodeURIComponent(location.hash.replace('/login', ''))
      if (window?.__g_router__) {
        window.__g_router__?.push({
          path: '/noappauth',
          query:{
            aid: appTool.getAppId(),
            tenantId: data.data?.tenant_id,
            unionId: data.data?.union_id,
            redirectUrl: redirectUrl
          }
        })
      } else {
        location.href = `/#/noappauth?aid=${appTool.getAppId()}&tenantId=${data.data?.tenant_id}&unionId=${data.data?.union_id}&redirectUrl=${redirectUrl}`;
      }
    }else if([1010012].includes(data.code)) {
      if (window?.__g_router__) {
        window.__g_router__?.push({
          path: '/noappauth',
          query:{
            code: data.code,
          }
        })
      } else {
        location.href = `/#/noappauth?code=${data.code}`
      }
      localStorage.clear();
    }else if([1010015].includes(data.code)){ //1010015 没有数据权限
      let msgInfo = JSON.parse(data.msg);
      location.href = `/#/${appTool.getAppName()}/nodataauth?aid=${appTool.getAppId()}&pname=${msgInfo.name}&user=${msgInfo.userName}&businessCode=${msgInfo.code}&dataid=${msgInfo.dataId}`;
      throw new Error('');

    }else if([9995].includes(data.code)){ //9995 数据不存在
      // if (typeof data.msg === 'string') {
      //   return
      // }
      if (!data.msg?.startsWith('{')) return
      let msgInfo = JSON.parse(data.msg);
      if (location.href.indexOf('dataid') > -1) { // 如果是外部跳转进来，则显示数据不存在页面
        location.href = `/#/${appTool.getAppName()}/nodata?aid=${appTool.getAppId()}&businessCode=${msgInfo.code}`;
      }
      throw new Error('');
      
    }else if([10005044].includes(data.code)){ //9995 数据不存在
      let msgInfo = JSON.parse(data.msg);
      location.href = `/#/${appTool.getAppName()}/closed?aid=${appTool.getAppId()}&pname=${msgInfo.projectName}&assettypename=${msgInfo.assetTypeName}&businessCode=${msgInfo.code}&dataid=${msgInfo.dataId}`;
      throw new Error('');
      
    }
    else if ([90000001].includes(data.code)) { // 机器人无权限操作
      context.result = -1
    } else if (!context.otherConfig.getContext) {
      if (data.code != 0) {
        context.error = {
          msg: data.msg,
        }
        if ([1010035].includes(data.code)) { // 数据收集 失效链接 不弹错误提示
          ignore = true
        }
        handleError(context, ignore)
      }
    }
  }else{
    context.error = response;
    handleError(context, ignore);
  }

  return context;
}

//错误统一处理
function handleError(context, ignore = false){
  if (!ignore) {
    context.error.msg != '登陆信息失效' && Message.error(context.error.msg); // 登陆信息失效不需要提示错误，已执行自动换token逻辑
  }
}

//处理路径中的参数
function handlePathParams(requestConfig, otherConfig){
  let url = requestConfig.url;
  let params = otherConfig.pathParams;
  let query = otherConfig.pathQuery || {};

  let result = url.replace(/:([a-zA-Z0-9\-_]+)/g, (a, b) => {
    if (params && (params[b] !== null ||  params[b] !== undefined)) {
      return params[b];
    }
    throw new Error(`参数“${b}”没有值，不符合预期`)
    // return a;
  });
  const str = queryString.stringify(query);
  if (str) {
    if (result.indexOf('?') < 0) {
      result += '?';
    } else {
      result += '&';
    }
    result += str;
  }
  requestConfig.url = result;
  return requestConfig;
}

//暴露出去ajax请求接口
export async function ajax(requestConfig, otherConfig={}, unknown = [], ignore = false){
  let context = {
    otherConfig
  };
  //是否有路径参数
  if(otherConfig.pathParams){
    context.requestConfig = handlePathParams(requestConfig, otherConfig);
  }
  context.requestConfig = mergeConfig(requestConfig, otherConfig);

  try{
    const res = await axios.request(context.requestConfig);
    // TODO: code error handling
    context.response = res;
    
    //context.result优先取返回结果中的data字段，如果没有，则返回整个结果
    if(res.data.hasOwnProperty('data')){
      context.result = res.data.data;
    }else{
      context.result = res.data;
    }
    // context.result = res.data.data || res.data
  }catch(e){
    context.result = e;
  }

  // console.log(context);
  context = handleResult(context, ignore);

  if(context?.result instanceof Error){
    throw context.result;
  }else if(context?.error){
    throw new Error(context.error.msg);
  }

  if(otherConfig.getContext){
    return context;
  }else{
    return context?.result;
  }
}