<script lang="tsx">
import { defineComponent } from 'vue'
export default defineComponent({
  name: "FileField",
  
})
</script>
<template>
  <a-upload
    v-if="isSimpleMode"
    ref="uploadRef"
    class="custom-upload"
    :auto-upload="true"
    :custom-request="handleCustomRequest"
    :show-cancel-button="false"
    :show-file-list="false"
    @before-upload="beforeUpload"
    draggable
  >
    <template #upload-button>
      <div :class="['upload-btn', {over: isDragover}]" @dragenter="isDragover = true" @dragleave="isDragover = false">
        <template v-if="isDragover">
          <i class="iconfont icon-icon_Import" />
          <span>拖拽至这里上传</span>
        </template>
        <template v-else>
          <div v-if="curfile?.name" class="loaded-cont">
            <FileIcon :file="curfile" :size="20" />
            <span class="file-name">
              <AutoTooltip :content="curfile.name" />
            </span>
            <icon-loading v-if="isUploading" />
            <i v-else class="iconfont icon-icon_delete" @click.stop="handleDeleteCurFile" />
          </div>
          <div v-else class="tips-cont">
            <p>点击或拖拽至这里<span class="text">上传本地文件</span></p>
            <p class="small-des">上传文件仅支持doc、docx</p>
          </div>
        </template>
      </div>
    </template>
  </a-upload>
  <a-popover
    v-else
    :popup-visible="popupShow"
    trigger="click"
    :content-class="[contentClass, customClass]"
    :position="position ?? 'bl'"
    :show-arrow="false"
    :update-at-scroll="true"
    :popupContainer="popupContainer"
    :default-popup-visible="defaultPopupVisible"
    :disabled="AIFileLoading"
    @update:popup-visible="handleUpdateVisible"
    :popup-translate="[0, -6]"
  >
    <!-- @popup-visible-change="handlePopupVisibleChanged" -->
    <slot>
      <renderTrigger ref="triggerRef" />
    </slot>

    <template #content>
      <!-- 上传拖住区域 -->
      <renderUploader />
      <!-- 已选择文件列表 -->
      <template v-if="curFileList?.length || tmpFileList?.length">
        <renderFileList />
      </template>

      <!-- 底部选择文档工具栏 -->
      <template v-if="!isDataReport">
        <Tools
          v-if="!['collectTzbd', 'collectGjzb', 'clueFilling'].includes(from)"
          v-model:fileList="curFileList"
          :tools="tools"
          :from="from"
          :data-id="dataId"
          :bCode="bCode"
          :fieldName="fieldName"
          :assetTypeId="assetTypeId"
          :type-list="businessTypeList"
          :default-tags="props.defaultTags"
          :update-file-tags="handleUpdateFileTags"
          v-on:upload-file-list="onUploadFileList"
        />
      </template>
    </template>
  </a-popover>
</template>

<script lang="tsx" setup name="FileField">
import { watchEffect, computed, ref, onMounted, watch, onBeforeUnmount } from 'vue'
import { useResizeObserver } from '@vueuse/core'
import { debounce } from 'lodash'
import { Upload, Tooltip, Message, Modal } from '@arco-design/web-vue'
import { useI18n } from 'vue-i18n'
import { getBigTypeByCode } from '@/utils/enums.js'
import uuid from '@/utils/uuid'
import FileIcon from '@/components/fileIcon.vue'
import Iconfont from '@/components/Iconfont.vue'
import TagSelect from '@/components/tagSelect/tagSelect.vue'
import { CalcWorker } from '@/components/tagUsers/getCalcWorker'
import { getAppName, getToken } from '@/utils/appTool.js'
import { useUploadFileList } from '@/store/useUploadFileList'
import { useUploader } from './useUploader'
import Tools from './tools.vue'
import FileItem from './fileItem.vue'
import AutoTooltip from '@/components/AutoTooltip.vue'
import {
  getFileBusiType,
  batchSaveFile,
  batchSaveFileNoOperateEvent,
  editFileBusinessType,
} from '@/services/investment/document.js'

const MAX_COUNT = 200
const { t: i18n_t } = useI18n()

const workerInstance = CalcWorker.prototype.getWorkerInstance()
const uploadFileListStore = useUploadFileList()

const { fileList: uploadingFileList } = uploadFileListStore

const props = defineProps<{
  notClearFileList?: { // 是否不清空文件列表,导入插件-BP解析导入（AI解析导入）时需要为true。
    type: Boolean,
    default: false,
  },
  editable?: {
    type: Boolean,
    default: false,
  }
  isAccont?: {
    type: Boolean,
    default: false,
  }
  isMiniBtn?: {
    type: Boolean,
    default: false,
  }
  isMiniScreen?: {
    type: Boolean,
    default: false,
  }
  AIFileLoading?: {
    type: Boolean,
    default: false,
  }
  fileList?: LocalFile[]
  position?: {
    type: Boolean,
    default: false,
  }
  bCode?: {
    type: String,
    default: ''
  }
  dataId?: {
    type: String,
    default: ''
  }
  popupContainer?: {
    type: String,
    default: ''
  }
  defaultPopupVisible?: {
    type: Boolean,
    default: false,
  }
  singleLine?: {
    type: Boolean,
    default: false,
  }
  from?: {
    type: String,
    default: ''
  }
  defaultTags?: {
    type: String,
    default: ''
  }[]
  tools?: UploaderToolConfig['action'][]
  transformLocalFile?: {
    type: Boolean,
    default: false
  },
  customClass?: {
    type: String,
    default: ''
  }
  // requestUrl: {
  //   type: string,
  //   default: ''
  // },
  withoutLogin?: {
    type: Boolean,
    default: false,
  },
  isSimpleMode?: { // 简单模式，只显示上传区域
    type: Boolean,
    default: false
  },
  accept?: {
    type: string,
    default: '*'
  },
  infoGatherId?: string,
  saveFileNoOperateEvent?: {
    type: Boolean,
    default: false,
  }, // 标记上传文件是否不需要记录动态 true标识不需要 false需要记录 (新建项目时 上传问件不需要动态，其他位置的上传文件都需要记录动态) 
  isDataReport?: {
    type: Boolean,
    default: false,
  }, // 是否是数据收集
  fieldName?: string, // 用于文件字段归档
  assetTypeId?: string, // 用于文件字段归档
  tenantId?: string, // 数据收集文件上传改分片
  PROPS_SOURCE_KEY?: string, // 父组件单独使用上传文件到飞书时，需要从store中获取对应的文件用这个标识去取
}>()

// const SOURCE_KEY = props.PROPS_SOURCE_KEY || uuid()
let SOURCE_KEY: any
watch(
  () => props.PROPS_SOURCE_KEY,
  (val) => {
    SOURCE_KEY = val || uuid()
  }, {
    immediate: true,
  }
)

const { uploadSingleFile, appendMultipleFileUpload, clearFileList, removeFile, reuploadFile } = useUploader('', props.tenantId)

const emit = defineEmits(['update:fileList', 'visible-change', 'waitUploadNum', 'deleteCurFile', 'uploadSuccess'])
const popupShow = ref(false)
const showTagList = ref<any[]>([])
const triggerRef = ref()
const triggerWidth = ref(0)
let contentClass = ref('uploader')
if (['message'].includes(props.from) || props.isMiniScreen) {
  contentClass.value = 'uploader from-message'
}

const isDragover = ref(false)
const isUploading = ref(false)
const curfile = ref()
const beforeUpload = (file: any) => {
  isDragover.value = false
  return new Promise((resolve, reject) => {
    if (!file.type) { // 不支持文件夹  
      // 包含小数点 并且 有后缀名
      if(file.name.indexOf('.')>-1&&file.name.split('.').pop()){
        if (['docx', 'doc'].includes(file.name.split('.').pop())) {
          curfile.value = file
          return resolve(file)
        } else {
          Message.error('不支持该类型文件')
          reject('不支持该类型文件')
        }
      }
    } else {
      if (file.type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document') {
        curfile.value = file
        resolve(file)
      } else {
        Message.error('不支持该类型文件')
        reject('不支持该类型文件')
      }
    }
  })
}
const handleDeleteCurFile = () => {
  curfile.value = null
  emit('deleteCurFile')
}

const changePopVisible = (visible = false) => {
  popupShow.value = visible
}

onMounted(() => {
  useResizeObserver(triggerRef.value, (entries) => {
    const entry = entries[0]
    const { width, height } = entry.contentRect

    triggerWidth.value = width - 46 // padding
  })
})

const isActive = ref(true)
onBeforeUnmount(() => {
  if (props.notClearFileList) return
  clearFileList(SOURCE_KEY)
  isActive.value = false
})

const tmpFileList = computed(() =>
  (uploadFileListStore.fileList ?? [])
    .filter((i) => {
      return i.sourceKey === SOURCE_KEY
    })
    .map((i) => i)
    .reverse(),
)
const curFileList = computed({
  get() {
    return props.fileList ?? []
  },
  set(v) {
    if (v?.length > MAX_COUNT) {
      v.splice(0, v?.length - MAX_COUNT) // 文件字段最多 MAX_COUNT 个
      Message.warning(i18n_t('plat_c.app_c.form.wenjian_limit', { count: MAX_COUNT }))
    }

    emit('update:fileList', v?.length ? v : null)
  },
})
// const width = computed(() => {
//   // TODO: 计算文件宽度，须考虑有没有 + 符号，可编辑与否，再去除后面的 arrow
//   return 300
// })

watchEffect(() => {
  const workerData = JSON.stringify({
    message: 'FileTag',
    data: {
      tags: props.fileList || [],
      labelKey: 'fileName',
      containerWidth: triggerWidth.value - 10, // TODO: 先简单处理下，这边计算好像还是有点问题
    },
  })

  workerInstance.postMessage(workerData).then((res) => {
    const data = res || {}
    // const { showTagList = [], editTagList = [] } = data
    showTagList.value = data.showTagList || []
  })
})

// 上传文件保存
const saveFile = (res: any, uploadFile: UploadFile) => {
  if (
    ['followRecord', 'followRecordEdit', 'indicators', 'message', 'dialog', 'noteField', 'collectTzbd', 'collectGjzb', 'clueFilling'].includes(props.from) || props.isDataReport
  ) { // 不走batchSave
    removeFile(uploadFile)
    res.type = 'userLocalFile'
    res.fileTags = props.defaultTags // 本地上传文件打默认标签
    curFileList.value = [
      ...curFileList.value,
      {
        ...res,
        source: 'upload',
      },
    ]

    return Promise.resolve(res)
  }

  if (!isActive.value) {
    return Promise.reject()
  }

  const reqFn = props.saveFileNoOperateEvent ? batchSaveFileNoOperateEvent : batchSaveFile
  return reqFn({
    data: [
      {
        dataId: props.dataId,
        fileTags: props.defaultTags ?? ['9999'],
        fileName: res.fileName,
        fileToken: res.fileToken,
        fileType: res.fileType,
        businessCode: props.bCode,
        fieldName: props.fieldName ? props.fieldName : undefined,
        assetTypeId: props.assetTypeId,
        // parentId: this.parentId, // 目前不需要
      },
    ],
  }).then((res) => {
    Message.success(
      i18n_t('plat_c.app_c.general_toast.shanchuanchenggong', { fileName: uploadFile.fileName }),
    )

    removeFile(uploadFile)
    const files = res.map((i: any) => {
      return {
        ...i,
        source: 'upload',
      }
    })
    curFileList.value = [...files, ...curFileList.value]
    if (props.isSimpleMode) {
      isUploading.value = false
      emit('uploadSuccess', res)
    }
    return res
  })
}
const getWaitUploadFileNum = () => {
  // 跟进记录上传本地文件时获取待上传文件数量，如果不为0表示有上传中文件，点击发布提示等待文件上传完成
  return tmpFileList.value.length
}
const closable = computed(() => tmpFileList.value.length === 0)

const handlePopupVisibleChanged = (v: boolean) => {
  emit('visible-change', v)
}

const handleUpdateVisible = (v: boolean) => {
  if (v || (closable.value && popupShow.value)) {
    handlePopupVisibleChanged(v)
    popupShow.value = v
  } else {
    const filenames = tmpFileList.value.map((f) => f.fileName)

    Modal.confirm({
      title: '提醒',
      okText: '确认',
      hideCancel: false,
      simple: false,
      closable: true,
      cancelText: '取消',
      modalStyle: {},
      modalClass: 'file-field-confirm-modal public-modal',
      content: () => (
        <span>
          <b>{filenames[0]}</b>
          {filenames.length > 1 ? `等${filenames.length}个文件` : ''}
          &nbsp;正在上传中，关闭弹窗将导致上传中断，是否确认关闭?
        </span>
      ),
      onOk() {
        clearFileList(SOURCE_KEY)
        handlePopupVisibleChanged(false)
        popupShow.value = false
      },
      onCancel() {
        handlePopupVisibleChanged(true)
        // @ts-ignore-next-line
        popupShow.value = Number(popupShow.value) + 1
      },
    })
  }
}

const onUploadFileList = (files: LocalFile[] = []) => {
  files = Array.from(files)
  let formData = ['collectTzbd'].includes(props.from) ? {
    dataId: props.dataId,
    fileTags: props.defaultTags ?? ['9999'],
    infoGatherId: props.infoGatherId,
  } : {
    businessCode: props.bCode,
    dataId: props.dataId,
    fileTags: props.defaultTags ?? ['9999'],
    transformLocalFile: props.transformLocalFile,
  }
  appendMultipleFileUpload(
    files,
    formData,
    {
      'login-token': getToken(),
    },
    saveFile,
    SOURCE_KEY,
    // props.requestUrl,
    props.withoutLogin
  )
  // 支持并发的话
  // const promises = Array.from(files).map((file) => {
  //   return uploadSingleFile(
  //     file,
  //     {
  //       dataId: props.dataId,
  //       fileTags: [],
  //     },
  //     {
  //       'login-token': getToken(),
  //     },
  //   )
  // })

  // Promise.all(promises)
  //   .then((res) => {
  //     batchSaveFile({
  //       data: res.map((data: any) => {
  //         return {
  //           dataId: props.dataId,
  //           fileTags: [],
  //           fileName: data.fileName,
  //           fileToken: data.fileToken,
  //           fileType: data.fileType,
  //           // parentId: this.parentId, // 目前不需要
  //         }
  //       }),
  //     }).then((res) => {
  //       Message.success('上传成功')

  //       clearFileList()
  //       // 外层把零时和有效数据一起传进来的，现在把零时数据更新成有效数据，不要删除
  //       curFileList.value = [...curFileList.value, ...res]
  //     })
  //   })
  //   .catch((res) => {
  //     Message.error('上传失败')
  //   })
}

const isUploadFileFn = (file: LocalFile | UploadFile, isTmp: boolean): file is UploadFile => {
  return isTmp
}
const handleOpenFile = (file: LocalFile) => {
  window.open(file.url)
}
const handleDeleteFile = (file: LocalFile | UploadFile, isTmp: boolean) => {
  const isUploadFile = isUploadFileFn(file, isTmp)
  if (isUploadFile) {
    uploadFileListStore.fileList = uploadFileListStore.fileList.filter((f) => f.uuid !== file.uuid)
  } else {
    if (file.id) {
      curFileList.value = curFileList.value.filter((f) => f.id !== file.id)
    } else if (file.fileKey) {
      curFileList.value = curFileList.value.filter((f) => f.fileKey !== file.fileKey)
    } else {
      curFileList.value = curFileList.value.filter((f) => f.fileToken !== file.fileToken)
    }
  }
}
const handleReuploadFile = (file: UploadFile) => {
  reuploadFile(
    file,
    {
      dataId: props.dataId,
      fileTags: props.defaultTags ?? ['9999'],
      transformLocalFile: props.transformLocalFile,
    },
    {
      'login-token': getToken(),
    },
    saveFile,
    SOURCE_KEY,
  )
}
const handleUpdateFileTags = (file: LocalFile, tags: LocalFile['fileTags'] = []) => {
  if (
    ['followRecord', 'followRecordEdit', 'indicators', 'message', 'dialog', 'noteField'].includes(props.from) &&
    !file.id
  ) {
    curFileList.value = curFileList.value.map((f) => {
      if (
        (file.fileToken && file.fileToken === f.fileToken) ||
        // @ts-ignore-next-line
        (file.fileKey && file.fileKey === f.fileKey)
      ) {
        return {
          ...f,
          fileTags: tags,
        }
      } else {
        return f
      }
    })
    return Promise.resolve()
  }
  return editFileBusinessType({
    data: {
      id: file.id,
      fileTags: tags,
      businessCode: props.bCode,
      fieldName: props.fieldName ? props.fieldName : undefined,
      assetTypeId: props.assetTypeId,
    },
  }).then(() => {
    // file.fileTags = tags
    curFileList.value = curFileList.value.map((f) => {
      if (file.id === f.id) {
        return {
          ...f,
          fileTags: tags,
        }
      } else {
        return f
      }
    })
  })
}

const renderFileList = () => {
  return (
    <div class="file-list">
      {tmpFileList.value.map((file) => {
        return (
          <FileItem
            key={`${file.uuid}`}
            isTmp={true}
            file={file}
            typeList={businessTypeList.value}
            onDelete={(file: UploadFile) => handleDeleteFile(file, true)}
            onReupload={handleReuploadFile}
          />
        )
      })}

      {curFileList.value.map((file) => {
        return (
          <FileItem
            key={file.id}
            file={file}
            class={['collectTzbd', 'collectGjzb', 'clueFilling'].includes(props.from) || props.isDataReport ? 'file-no-select-tag' : ''}
            typeList={businessTypeList.value}
            onOpen={handleOpenFile}
            onDelete={(file: LocalFile) => handleDeleteFile(file, false)}
            onUpdateFileTags={handleUpdateFileTags}
          />
        )
      })}
    </div>
  )
}
const preUploadFileList = ref<LocalFile[]>([])
const debounceUpload = debounce(() => {
  onUploadFileList(preUploadFileList.value)
  // 清除临时数据
  preUploadFileList.value = []
}, 300)
const handleCustomRequest = (params: { fileItem: { file: LocalFile }}, asc = true) => {
  isUploading.value = true
  // preUploadFileList.value.push(params.fileItem.file)
  asc ? preUploadFileList.value.push(params.fileItem.file) : preUploadFileList.value.unshift(params.fileItem.file)
  debounceUpload()
}

const renderUploader = () => {
  return (
    <div class="uploader-content">
      <Upload
        draggable
        action="/"
        multiple
        disabled={!props.editable}
        auto-upload={true}
        show-file-list={false}
        custom-request={handleCustomRequest}
        // on-button-click={() => Promise.reject()}
        v-slots={{
          'upload-button': () => (
            <div
              class="empty-wrapper"
              style="font-size: 14px;font-weight: 500;color: #4E5969;user-select: none;"
            >
              {props.editable ? (
                <span v-html={i18n_t('plat_c.app_c.form.tuozhuaitishi')}></span>
              ) : (
                i18n_t('plat_c.app_c.form.zanwuwenjian')
              )}
            </div>
          ),
        }}
      />
    </div>
  )
}

const businessTypeList = ref<any[]>([])

const fetchBusinessType = async () => {
  return await getFileBusiType({
    data: {
      dataId: props.dataId,
      dataType: getBigTypeByCode(props.bCode),
    },
  }).then((res: { typeVOList: any[]; total: number }) => {
    const typeList = res.typeVOList
    businessTypeList.value = typeList
  })
}
watch(
  () => [props.dataId, props.bCode],
  () => {
    if (!['collectTzbd', 'collectGjzb', 'clueFilling'].includes(props.from)) {
      !props.isDataReport && fetchBusinessType()
    }
  },
  {
    immediate: true,
  },
)

const renderTrigger = () => {
  if (props.isAccont) {
    return (
      <div class="uploader-trigger isAccont">
        <renderEmptyTitle />
      </div>
    )
  } else {
    return (
      <div class={["uploader-trigger", popupShow.value ? 'uploader-active' : '', {disabled: props.AIFileLoading}]}>
        {props.fileList?.length ? <renderFileTagList /> : <renderEmptyTitle />}
        <Iconfont name="icon_d_arrow" size={14} />
        {props.AIFileLoading ? <span class="progress-loading">
          <icon-loading /> 
          <a-statistic value={99} value-from={0} start animation />
          <span>%</span>
        </span> : ''}
      </div>
    )
  }
}
const onPreviewFile = (url: LocalFile['url']) => {
  url && window.open(url)
}

const renderFileTagList = () => {
  const fileList = props.singleLine ? showTagList.value : props.fileList

  return (
    <>
      <div class="add">
        <Iconfont name="icon_add" size={12} />
      </div>
      <div class="file-tag-wrapper">
        {fileList.map((file) => {
          return file.cType !== 'more' ? (
            <div class="file-tag-item" onClick={() => onPreviewFile(file.url)}>
              <FileIcon file={file} size={16} />
              <Tooltip content={file.fileName} disabled={file.displayedLabel === file.fileName}>
                <span class="label">{file.displayedLabel ?? file.fileName}</span>
              </Tooltip>
            </div>
          ) : (
            <Tooltip
              content-class="file-field-comp-content"
              v-slots={{
                content: () => (
                  <>
                    {(file.restTags ?? []).map((f: any) => {
                      return (
                        <div class="tooltip-file-tag-item" onClick={() => onPreviewFile(f.url)}>
                          <FileIcon file={f} size={16} />
                          <span class="label">{f.fileName}</span>
                        </div>
                      )
                    })}
                  </>
                ),
              }}
            >
              <div class="file-tag-item more">
                <span class="label">{file.displayedLabel}</span>
              </div>
            </Tooltip>
          )
        })}
      </div>
    </>
  )

  // return showTagList.value.map((tag: any) => {
  //   return (
  //     <div class="file-tag-item">
  //       {tag.cType !== 'more' && <FileIcon file={tag} size={16} />}

  //       {tag.displayedLabel !== tag.label ? (
  //         <Tooltip content={tag.label}>
  //           <span class="label">{tag.displayedLabel}</span>
  //         </Tooltip>
  //       ) : (
  //         <span class="label">{tag.displayedLabel}</span>
  //       )}
  //     </div>
  //   )
  // })
}
const renderEmptyTitle = () => {
  if (props.isAccont) {
    return (
      <a-tooltip disabled={!props.isMiniBtn} content="文件">
        {
          props.isMiniBtn ? (
            <div class="trigger-empty account"><i class="iconfont icon-icon_12" style="margin-right: 0" /></div>
          ) : (
              <div class="trigger-empty account">
                <i class="iconfont icon-icon_12" />
                文件
              </div>
          )
        }
      </a-tooltip>
    )
  } else {
    return (
      <div class="trigger-empty normal">
        {props.editable && <Iconfont name="icon_add" size={12} />}
        {props.editable ? (
          <span class=" trigger-empty-text">{i18n_t('plat_c.app_c.form.tianjiawenjian')}</span>
        ) : (
          <span class=" trigger-empty-text">{i18n_t('plat_c.app_c.form.zanwuwenjian')}</span>
        )}
      </div>
    )
  }
}

defineExpose({ getWaitUploadFileNum, changePopVisible, closable, handleCustomRequest, handleReuploadFile })
</script>

<style lang="scss" scoped>
.custom-upload {
  width: 100%;
  .upload-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 84px;
    padding: 20px 0;
    border-radius: 6px;
    background: #F4F6F7;
    box-sizing: border-box;
    border: 1px dashed #DEE0E3;
    transition: .2s;
    &.over {
      color: #86909C;
      background: #EEF4FF;
      border: 1px dashed #126FFF;
      * {
        pointer-events: none; // 解决拖动到子元素时触发父元素的dragleave事件导致的闪烁问题
      }
    }
    &:hover {
      border: 1px dashed #126FFF;
    }
    .loaded-cont {
      width: 80%;
      display: flex;
      justify-content: center;
      align-items: center;
      .file-name {
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        margin: 0 4px;
        font-family: 苹方-简;
        font-size: 14px;
        font-weight: normal;
        line-height: 20px;
        color: #1F2329;
      }
      .icon-icon_delete {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 24px;
        height: 24px;
        border-radius: 6px;
        background: #F4F6F7;
        color: #646A73;
        cursor: pointer;
        transition: .2s;
        &:hover {
          background: rgba(31, 35, 41, 0.08);
        }
      }
    }
    .icon-icon_Import {
      color: #C9CDD4;
      font-size: 28px;
      margin-right: 12px;
    }
    .text {
      font-weight: 500;
    }
    .tips-cont {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      .small-des {
        font-family: PingFang SC;
        font-size: 12px;
        font-weight: normal;
        line-height: 18px;
        color: #86909C;
        margin-top: 6px;
      }
    }
  }
}
.uploader-trigger {
  // border: 1px solid red;
  // min-width: 200px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  max-height: 157px;
  cursor: pointer;
  &.disabled {
    cursor: not-allowed;
    border-color: #e4e5eb !important;
    background: #f4f6f7;
  }
  :deep() {
    .progress-loading {
      display: flex;
      align-items: center;
      color: #86909C;
      font-size: 12px;
      padding-right: 10px;
      .arco-icon-loading {
        font-size: 14px;
        margin-right: 4px;
      }
      .arco-statistic {
        line-height: 14px;
      }
      .arco-statistic-value {
        font-weight: normal;
        font-size: 12px;
        color: #86909C;
      }
    }
    .trigger-empty {
      display: flex;
      width: 100%;
      height: 38px;
      font-size: 14px;
      color: #999;
      cursor: pointer;
      line-height: 38px;
      align-items: center;
      padding: 0 7px;
      .trigger-empty-text {
        color: #86909C;
      }
      .iconfont {
        height: 20px;
        width: 20px;
        border-radius: 2px;
        display: inline-block;
        text-align: center;
        line-height: 20px;
        transition: all ease 300ms;
        background: #eef0f0;
        color: #646a73;
        margin-right: 4px;
      }
    }
    .add {
      display: flex;
      align-items: flex-start;
      height: 100%;
      position: absolute;
      width: 27px;
      padding-left: 7px;
      padding-top: 6px!important;
      .iconfont {
        height: 20px;
        width: 20px;
        border-radius: 2px;
        display: inline-block;
        text-align: center;
        line-height: 20px;
        transition: all ease 300ms;
        background: #eef0f0;
        color: #646a73;
        &::before {
          font-size: 12px;
          transform: scale(0.8333);
          transform-origin: center;
        }
      }
    }
    .file-tag-wrapper {
      overflow: hidden;
      flex: 1;
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      padding-bottom: 8px;
      padding-left: 26px;
      padding-right: 20px;
      max-height: 157px;
      overflow: auto;
      .file-tag-item {
        display: flex;
        align-items: center;
        // margin-right: 4px;
        padding: 0 8px;
        height: 20px;
        line-height: 20px;
        border-radius: 10px;
        user-select: none;
        margin-top: 8px;
        overflow: hidden;
        max-width: 100%;
        cursor: pointer;
        &:hover {
          .label {
            color: #3370ff;
          }
        }
        
        .label {
          font-family: PingFangSC-Regular;
          font-size: 14px;
          font-weight: normal;
          line-height: 20px;
          letter-spacing: 0px;
          color: #1f2329;
          padding-left: 2px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          transition: all ease-in-out 300ms;
        }
        &.more {
          background-color: #eef0f1;
          .label{
            font-size: 12px;
            font-weight: 700;
            padding: 0;
          }
        }
      }
    }
    .icon-icon_d_arrow {
      margin-right: 10px;
      font-size: 12px;
      transform: scale(0.8333);
      color: #646a73;
      position: absolute;
      right: 0;
    }
  }
}
</style>

<style lang="scss">
.file-field-confirm-modal {
  width: 468px;
  &.arco-modal {
    padding: 0 24px;
    .arco-modal-header {
      padding: 0;
      height: 48px;
      line-height: 48px;
      margin: 0;
      .arco-modal-title {
        display: flex;
        align-items: center;
        .title {
          font-size: 16px;
          font-weight: 500;
        }
      }
      .arco-icon-hover {
        &::before {
          border-radius: 4px;
          text-align: center;
          line-height: 20px;
        }
      }
    }
    .arco-modal-body {
      padding: 32px 0;
    }
    .arco-modal-footer {
      margin: 0;
      text-align: right;
      height: 54px;
      line-height: 54px;
      padding: 0;
    }
  }
}
.file-field-comp-content {
  .tooltip-file-tag-item {
    display: flex;
    align-items: center;
    height: 20px;
    line-height: 20px;
    border-radius: 10px;
    user-select: none;
    overflow: hidden;
    max-width: 100%;
    margin-bottom: 6px;
    &:last-child {
      margin-bottom: 0;
    }
    cursor: pointer;
    &:hover {
      .label {
        text-decoration: underline;
      }
    }

    .label {
      font-family: PingFangSC-Regular;
      font-size: 14px;
      font-weight: normal;
      line-height: 20px;
      letter-spacing: 0px;
      color: white;
      padding-left: 2px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
}
.uploader {
  padding: 8px !important;
  width: 454px !important;
  &.from-message {
    width: 100% !important;
    box-sizing: border-box;
    .file-list {
      width: 100%;
    }
    .file-item {
      width: 100%;
      .file-icon, .file-action {
        flex-shrink: 0;
      }
      .upload-file-info {
        max-width: calc(100% - 80px);
        width: 272px;
        .label {
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
        .custom-tag-list-outer {
          width: 100% !important;
        }
      }
    }
    .tool-trigger {
      flex-shrink: 0;
      min-width: 120px
    }
    .action-wrapper {
      flex-grow: 1;
      width: auto;
    }
  }
  .arco-popover-content {
    margin: 0;
  }
  .arco-upload {
    border-radius: 6px;
    background: #f4f6f7;
    box-sizing: border-box;
    border: 1px dashed #dee0e3;
    height: 96px;
    line-height: 96px;
    text-align: center;
  }
  .tool-wrapper {
    margin-top: 8px;
  }
  .file-list {
    max-height: 188px;
    overflow-y: auto;
    margin-top: 12px;
  }
}
</style>
