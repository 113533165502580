
import { getAppInfo, getAppNameById, getLarkAppInfo } from '@/services/other/index.js';
import { Message } from '@arco-design/web-vue';
import  store  from '@/store/index'


let appName;
let localToken;
let localOpenId;
let appId;
let localTenantId;
// const APPNAME_BY_DATATYPE = {
//   1: '项目管理',
//   2: '已投公司',
//   3: '基金管理',
//   4: '投资者管理',
//   5: '项目管理',
//   6: '已投公司',
//   7: '项目管理',
//   8: '已投公司',
// }
// 
export const APPNAME_BY_BUSINESSCODE = {
  'beforeProject': '项目管理',
  'investedCompany': '已投公司',
  'fund': '基金管理',
  'lpinvestor': '投资者管理',
  'beforeCoin': '项目管理',
  'beforeFof': '项目管理',
  'afterCoin': '已投公司',
  'afterFof': '已投公司',
}
const BUSINESSCODE_BY_DATATYPE = {
  1: 'beforeProject',
  2: 'investedCompany',
  3: 'fund',
  4: 'lpInvestor',
  5: 'beforeCoin',
  6: 'afterCoin',
  7: 'beforeFof',
  8: 'afterFof',
}

const APPNAME_ROUTE_MAP = {
  '项目管理': 'before',
  '已投公司': 'after',
  '基金管理': 'fund',
  '投资者管理': 'lpinvestor',
  '基金分析': 'fundanalyze',
  '后台管理': 'manage',
}

const PATH_CODE_MAP = {
  'beforeProject': 'before',
  'beforeCoin': 'before',
  'beforeFof': 'before',
  'investedCompany': 'after',
  'afterCoin': 'after',
  'afterFof': 'after',
  'fund': 'fund',
  'lpInvestor': 'lpinvestor',
}

//根据code获取指定应用的路径
export function getPathByCode(code) {
  return PATH_CODE_MAP[code] || 'before';
}

//根据dataType获取应用名称 ***有问题，先注释掉，因为币权在投前，投后是区分不出来的，库里是一份数据***
// export function getAppNameByDataType(type){
//   return APPNAME_BY_DATATYPE[type];
// }

//根据businessCode获取应用名称
export function getBusinessCodeByDataType(type) {
  return BUSINESSCODE_BY_DATATYPE[type];
}

//格式化url, 提取参数
export function formatURL(tmpRoute) {
  appName = tmpRoute.path.replace('/', '');
  appId = tmpRoute.fullPath.match(/[&|?]aid=([^&]*)($|&)/i);
  appId = (appId && appId[1]) || '';
}

//设置值[before, after, fund, datacollect, fundanalyze, helper, lpinvestor]
export function setAppName(name) {
  appName = name;
}

//返回值[before, after, fund, datacollect, fundanalyze, helper, lpinvestor]
export function getAppName() {
  if (appName) return appName;
  // alert(location.hash);
  appName = location.hash.split('?')[0].replace('/login', '').split('/')[1];  //取#后第一段路径作为appName
  return appName;
}

//返回当前租户id
export function getTenantId() {
  if (localTenantId) return localTenantId;
  localTenantId = localStorage.getItem(getAppId() + '_tenantId' ) || '';
  return localTenantId;
}
export function getSlientTenantId() {
  if (localTenantId) return localTenantId;
  localTenantId = localStorage.getItem(getAppId() + '_slientTenantId' ) || '';
  return localTenantId;
}
//移除当前租户id
export function removeTenantId() {
  localStorage.removeItem(getAppId() + '_tenantId');
}
export function removeSlientTenantId() {
  localStorage.removeItem(getAppId() + '_slientTenantId');
}

//返回当前应用id
export function getAppId() {
  if (appId) return appId;
  appId = location.href.match(/[&|?]aid=([^&]*)($|&)/i);
  appId = (appId && appId[1]) || '';
  return appId;
}

//移除当前应用token
export function removeToken() {
  localStorage.removeItem(getAppName() + '_' + getAppId() + '_token');
  localStorage.removeItem(getAppName() + '_' + getAppId() + '_token_time')
}

//获取当前应用token
export function getToken() {
  if (localToken) return localToken;
  localToken = localStorage.getItem(getAppName() + '_' + getAppId() + '_token') || '';
  return localToken;
}
//获取当前应用openId
export function getOpenId() {
  if (localOpenId) return localOpenId;
  localOpenId = localStorage.getItem(getAppName() + '_' + getAppId() + '_openid') || '';
  return localOpenId;
}

//判定token是否过期，后端默认3天，前端默认2天
export function tokenExpire() {
  let tokenTime = localStorage.getItem(getAppName() + '_' + getAppId() + '_token_time');
  console.log('tokenTime', tokenTime)
  if (!tokenTime) return true; //没有记录时间，默认过期
  return new Date().getTime() - tokenTime >= 1000 * 60 * 60 * 24 * 2; //超过2天算过期
}

//设置当前应用token
export function setToken(token) {
  localStorage.setItem(getAppName() + '_' + getAppId() + '_token', token);
  localStorage.setItem(getAppName() + '_' + getAppId() + '_token_time', new Date().getTime());
}
// 设置当前租户ID

export function setTenantId(tenantId) {
  localStorage.setItem(getAppId() + '_tenantId', tenantId);
  localStorage.setItem(getAppName() + '_' + getAppId() + '_tenantId_time', new Date().getTime());
}
// 设置 unionId
export function setUnionId(unionId) {
  localStorage.setItem(getAppId() + '_unionId', unionId);
}
// 获取 unionId
export function getUnionId() {
  localStorage.getItem(getAppId() + '_unionId');
}
export function setSlientTenantId(tenantId) {
  localStorage.setItem(getAppId() + '_slientTenantId', tenantId);
}

//设置当前应用openId
export function setOpenId(openId) {
  localStorage.setItem(getAppName() + '_' + getAppId() + '_openid', openId);
}

//是否在飞书内
export function isInFeishu() {
  return location.href.indexOf('feishu=1') > -1 || navigator.userAgent.indexOf('Lark') > -1
}

//获取其他应用详情页链接, appName: 项目管理/已投公司/基金管理/数据收集/投资者管理
export async function getDetailLink(appName, businessCode, dataId, infoGatherId = '', reportDate = '') {
  let res = await getAppInfo({
    data: {
      name: appName
    }
  });
  // console.log(res,dataId, 'dataId', businessCode, 'businessCode')
  if (res) {
    if (appName == '数据收集') {
      return `https://applink.feishu.cn/client/web_app/open?appId=${res[0]['larkAppId']}&pid=${dataId}&reload=true`
    } else {
      return `https://applink.feishu.cn/client/web_app/open?appId=${res[0]['larkAppId']}&businessCode=${businessCode}&dataid=${dataId}&reload=true`
    }

  }
  return ''
}
//获取其他应用详情页链接, appName: 项目管理/已投公司/基金管理/数据收集/投资者管理 / 投资云助手
// larkType: 1: 自建应用 2：商店应用
export async function getLarkDetailLink({name, larkType}) {
  let res = await getLarkAppInfo({
    params: {
      name,
      larkType
    }
  });
  return res;
}


//获取其他应用详情页链接, appName: 项目管理/已投公司/基金管理/数据收集
export async function getDetailLinkList(appName) {
  let res = await getAppInfo({
    data: {
      name: appName
    }
  });
  if (res) {
    return `https://applink.feishu.cn/client/web_app/open?appId=${res[0]['larkAppId']}&reload=true`
  }
  return ''
}



export async function getAppById(appId) {
  let res = await getAppNameById({
    params: {
      appId: appId
    }
  });
  if (!res) return;
  return {
    name: res,
    path: APPNAME_ROUTE_MAP[res] || ''
  }
}

// 设置用户语言
export function setLanguage(language) {
  localStorage.setItem('lang', language);
}

// 获取用户语言
export function getLanguage() {
  return localStorage.getItem('lang');
}
export const copyToClipBoard = (text) => {
  if (navigator.clipboard && window.isSecureContext) {
    navigator.clipboard.writeText(text)
  } else {
    // 创建text area
    const textArea = document.createElement('textarea')
    textArea.value = text
    // 使text area不在viewport，同时设置不可见
    document.body.appendChild(textArea)
    textArea.focus()
    textArea.select()
    return new Promise((resolve, reject) => {
      // 执行复制命令并移除文本框
      document.execCommand('copy') ? resolve() : reject(new Error('出错了'))
      textArea.remove()
    })
  }
}

// 根据应用类型不同跳转至后台管理的方法不同
export const toBackgroudManage = (tab, queryParams) => {
  const { query } = window.__g_router__.currentRoute.value;
  const aid = query.aid
  const {appType} = store.state
  // 1: 自建应用 2： ISV
  if(appType === 1) {
    getDetailLinkList('后台管理').then((res) => {
      if(res) {
        const url = `${res}&tab=${tab}&${queryParams}`
        window.open(url)
      }
    })
  } else {
    getLarkDetailLink({
      name: '投资云助手',
      larkType: 2
    }).then((res) => {
      if(res) {
        const url = `${location.origin}/#/manage?aid=${res}&tab=${tab}&${queryParams}`
        const tmp = encodeURIComponent(url)
        window.open(`https://applink.feishu.cn/client/web_app/open?appId=${res}&lk_target_url=${tmp}`)
      }
      
    })
    // 区分自建应用和商店应用
    // const url = `${location.origin}/#/manage?aid=${aid}&tab=${tab}&${queryParams}`
    // const tmp = encodeURIComponent(url)
    // window.open(`https://applink.feishu.cn/client/web_app/open?appId=${aid}&lk_target_url=${tmp}`)
  }
}
export const handleGoDetailOfApp = (FromBcode, ToBcode, ToDataId, fromDataId, tab = '') => {
  if (fromDataId && ToDataId && (fromDataId == ToDataId)) return
  let fromAppName = APPNAME_BY_BUSINESSCODE[FromBcode], toAppName = APPNAME_BY_BUSINESSCODE[ToBcode]
  if (fromAppName == toAppName) { // 相同应用
    const route = window.__g_router__.currentRoute.value
    window.__g_router__.push({
      query: {
        ...route.query,
        businessCode: ToBcode,
        dataid: ToDataId,
        tab: tab,
      }
    })
  } else {
    getDetailLink(toAppName, ToBcode, ToDataId).then((res) => {
      window.open(res)
    })
  }
}
