<template>
  <a-dropdown
    class="custom-tag-select"
    v-model:popup-visible="popupVisible"
    trigger="click"
    :default-popup-visible="defaultPopupVisible"
    :popupContainer="popupContainer"
    :style="{ '--w': `${triggerWidth}px`, '--h': `calc(${optionHeight} + 38px)`, '--z': zIndex }"
    :position="position"
  >
    <div class="custom-tag-list-outer" ref="selectDom">
      <tagList
        v-if="finished"
        ref="tagList"
        :from="from"
        :tags="optionTagsValue"
        :state="state"
        :labelKey="optionKeys.labelKey"
        @delVal="handleDel"
      />
    </div>
    <template #content v-if="finished">
      <div
        :class="['dropdown', from]"
        :style="resizedStyle"
        @click="onPreventEvent"
        @pointerdown="onPreventEvent"
        @pointerup="onPreventEvent"
      >
        <a-input
          ref="searchInputRef"
          class="search-input"
          v-model="keyword"
          :placeholder="$t('plat_c.app_c.form.chazhaoxuanxiang')"
          @update:model-value="$emit('update-keyword', $event)"
        />
        <div class="option-list" :style="'max-height:' + optionHeight">
          <template v-if="filteredOptions.length > 0">
            <template v-if="renderFn">
              <RenderOpt
                v-for="(option, index) in filteredOptions"
                :option="option"
                :labelKey="labelKey"
                :render-fn="renderFn"
                :key="index"
                @click="clickOption(option)"
              />
            </template>
            <template v-else>
              <div
                v-for="(option, index) in filteredOptions"
                :class="{
                  'option-item':true,
                  'error-option':from=='excelTable'&& option?.error,
                  'error-option-hidden':from=='excelTable'&& option?.hidden
                }"
                :key="option[idKey]"
                @click="clickOption(option)"
              >
                <span class="label" :style="'background:' + option[colorKey]">
                  {{ option[labelKey] }}
                </span>
                <i v-if="checkItem(option)" class="iconfont icon-checked icon-close-med" />
              </div>
            </template>
          </template>
          <template v-else>
            <div class="no-data">
              <slot name="empty">{{ $t('plat_c.app_c.form.meiyouxuanxiang') }}</slot>
            </div>
          </template>
        </div>

        <slot name="footer"></slot>
      </div>
    </template>
  </a-dropdown>
</template>

<script>
import _, { lowerCase } from 'lodash'
import { onClickOutside } from '@vueuse/core'
import tagList from '@/components/tagList/index.vue'
import useSize from '@/composables/useSize'
import AutoTooltip from '@/components/AutoTooltip.vue'
import RenderOpt from './renderOpt.vue'

const W_HALF = 10 //字母数字符号宽度, 字符从7~10不等，字母约为8.7，数字约为7.2 不再精确计算
const W_FULL = 12 //汉字宽度，跟font-size大小一样

export default {
  name: 'CustomTagSelect',
  components: {
    tagList,
    AutoTooltip,
    RenderOpt,
  },
  props: {
    position: {
      type: String,
      default: 'bottom',
    },
    modelValue: [String, Number, Array],
    defaultPopupVisible: Boolean,
    state: {
      type: Number,
      default: 0,
    },
    from: {
      type: String,
      default: 'normal', //组件使用来源，filter筛选，normal其他
      // default: ()=>{ return 'normal' }
    },
    type: {
      type: String,
      default: 'single',
    }, //single 单选， multiple 多选
    // multiple: {
    //   type: Boolean,
    // },
    options: Array,
    popupContainer: String,
    optionHeight: {
      type: String,
      default: () => {
        return '100px'
      },
    },
    optionKeys: {
      type: Object,
      default() {
        return {
          label: 'label',
          id: 'id',
          color: 'color',
        }
      },
    },
    renderFn: {
      type: Function,
    },
    zIndex: {
      type: Number,
      default: 2000,
    },
    needReqSearch: { // 搜索师傅需要走接口
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      localValues: null,
      localOptions: [],
      keyword: '',
      triggerWidth: 0,
      finished: false,
      popupVisible: false,
      resizedStyle: {},
    }
  },
  computed: {
    // isMultiple() {
    //   return this.multiple !== false
    // },
    multiple() {
      return this.type === 'multiple'
    },
    labelKey() {
      return this.optionKeys.label
    },
    idKey() {
      return this.optionKeys.id
    },
    colorKey() {
      return this.optionKeys.color
    },
    filteredOptions() {
      // if (typeof this.$props.renderFn === 'function') {
      //   return this.options
      // }

      if(this.needReqSearch) { // 需要走搜索接口就不需要自己过滤了 
        return this.options
      }

      const lowerCaseKeyword = this.keyword?.toLowerCase() ?? ''

      return this.options.filter((opt) => {
        const lowerCaseLabel = opt[this.labelKey]?.toLowerCase() ?? ''

        return lowerCaseLabel.includes(lowerCaseKeyword)
      })
    },
    optionTagsValue() {
      const values = this.multiple
        ? this.localValues || []
        : this.localValues
        ? [this.localValues]
        : []

      return values
        .map((value) => {
          const option = this.options.find((opt) => {
            return opt[this.idKey] === value
          })
          return option
            ? {
                ...option,
                label: option[this.labelKey],
                color: option[this.colorKey],
              }
            : null
        })
        .filter((i) => i !== null)
      // return this.options
      //   .filter((opt) => {
      //     return values.includes(opt[this.idKey])
      //   })
      //   .map((i) => {
      //     return {
      //       ...i,
      //       color: i[this.colorKey],
      //     }
      //   })
    },
  },
  watch: {
    modelValue: {
      immediate: true,
      deep: true,
      handler(v) {
        this.localValues = _.cloneDeep(v)
      },
    },
    options: {
      immediate: true,
      deep: true,
      handler(v) {
        this.localOptions =   _.cloneDeep(this.formatOptions(v))
      },
    },
    keyword: {
      immediate: true,
      handler(v) {
        this.filterOptions()
      },
    },
    popupVisible: {
      handler: function (n) {
        if (n) {
          this.$nextTick(() => setTimeout(() => this.$refs.searchInputRef?.focus(), 100))
        }
      },
      immediate: true,
    },
    // localValues: {
    //   deep: true,
    //   handler(v) {
    //     this.$emit('update:model-value', this.localValues)
    //   }
    // },
  },
  methods: {
    onPreventEvent(evt) {
      evt.stopPropagation()
      evt.preventDefault()
    },
    checkItem(opt) {
      if (this.from != 'filter') return false
      const values = this.multiple
        ? this.localValues || []
        : this.localValues
        ? [this.localValues]
        : []

      return values.includes(opt[this.idKey])
    },
    focus() {
      this.popupVisible = true
    },
    filterOptions() {
      let word = this.keyword
      let tmpList = this.options.filter((ii) => {
        return (ii[this.labelKey] ?? '').includes(word)
      })
      this.localOptions = this.formatOptions(tmpList)
    },
    //格式化选项
    formatOptions(list) {
      let tmpList = _.cloneDeep(list)
      tmpList.forEach((ii) => {
        ii.showLabel = ii[this.labelKey]
      })
      return tmpList
    },
    clickOption(option) {
      const value = option[this.idKey]
      if (!this.multiple) {
        this.localValues = this.localValues === value ? null : value

        this.popupVisible = false
      } else {
        this.localValues = this.localValues?.includes(value)
          ? this.localValues.filter((v) => v !== value)
          : [...(this.localValues ? this.localValues : []), value]
      }
      this.keyword = ''
      this.$emit('update:model-value', this.localValues)
    },
    handleDel(index) {
      if (this.type == 'single') {
        this.localValues = null
      } else {
        this.localValues.splice(index, 1)
      }
      this.$emit('update:model-value', this.localValues)
      this.$emit('update-keyword', this.localValues)
    },
  },
  mounted() {
    // 外部挂载特殊处理，不然宽度取不到，会导致计算错误
    this.$nextTick(() => {
      setTimeout(() => {
        const { selectDom } = this.$refs
        let boundingClientRect=selectDom?.getBoundingClientRect()
        if(!boundingClientRect)return
        const { width } = boundingClientRect

        this.triggerWidth = width
        this.finished = true

        // const [style, unobserverHandler] = useSize(selectDom, (rect) => {
        //   this.resizedStyle = {
        //     width: `${rect.width}px`,
        //   }
        // })
        // this.unobserverHandler = unobserverHandler

        // vue3 没有 once 方法，所以用一个放到 this 上，然后在 unmounted 里面清除
        // this.$once('hook:beforeDestroy', () => {
        //   unobserverHandler()
        // })
      }, 0)
    })
  },
  beforeDestroy() {
    // this.unobserverHandler && this.unobserverHandler()
  },
}
</script>

<style lang="scss">
.custom-tag-select {
  width: var(--w);
  z-index: var(--z) !important;
  // width: 100%;
  .arco-dropdown {
    border-radius: 6px;
    padding: 0;
    border: 1px solid #dbdfe1;
  }
  .arco-dropdown-list-wrapper {
    overflow: hidden;
    max-height: var(--h);
  }
}
</style>

<style lang="scss" scoped>
.custom-tag-list-outer {
  padding: 6px 10px;
  min-height: 30px;
  max-height: 60px;
  overflow-y: auto;
  overflow-x: hidden;
  // border: 1px solid pink;
  width: 100%;
  :deep(.icon-arrow) {
    line-height: 20px;
    margin-top: 0;
  }
}
.dropdown {
  width: 100%;
  border-radius: 6px;
  border: none;
  .search-input {
    border: none;
    height: 38px;
    padding: 0 8px;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    border-bottom: 1px solid #dbdfe1;
  }
  .option-list {
    margin-right: 3px;
    padding: 4px 0 4px 4px;
    width: 100%;
    overflow-y: auto;
    .option-item {
      &.error-option{
        display:none;
        color:red !important;
        .label{
          background: #E0E9FF !important;
        }
      }
      &.error-option-hidden{
        display:none;
      }
      margin: 0 5px 0 0;
      padding: 6px 8px;
      height: 32px;
      line-height: 32px;
      cursor: pointer;
      &:hover {
        background: #eceeee;
        border-radius: 4px;
      }
      .label {
        display: block;
        padding: 0 8px;
        height: 20px;
        width: fit-content;
        max-width: 100%;
        line-height: 20px;
        border-radius: 10px;
        font-size: 12px;
        font-weight: 500;
        user-select: none;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
    .no-data {
      text-align: center;
      // margin: 4px 0;
      font-size: 12px;
      font-family: PingFangSC;
      color: #86909c;
      font-weight: 400;
      font-size: 14px;
      height: 32px;
      line-height: 32px;
    }
  }
  &.filter {
    .option-list {
      .option-item {
        display: flex;
        align-items: center;
        justify-content: space-between;
        .icon-checked {
          margin-left: 4px;
          font-size: 18px;
          color: #236bf8;
        }
      }
    }
  }
}
</style>
